<template>
    <div>
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/Manager' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>职务津贴管理</el-breadcrumb-item>
            <el-breadcrumb-item>津贴列表</el-breadcrumb-item>
        </el-breadcrumb> -->
        <el-card class="box-card">
            <el-row :gutter="30">
                <el-col :span="8">
                    <!--搜索-->
                    <el-input placeholder="请输入津贴类型名称" v-model="queryInfo.type" clearable @clear="GetAllowanceTypeDic"> 
                        <el-button slot="append" icon="el-icon-search"  @click="GetAllowanceTypeDic"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="addTypeDialogVisible = true">添加津贴类型</el-button>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="ShowAddMoneyDialog">添加津贴金额</el-button>
                </el-col>
            </el-row>
            <tree-table class="treetable" 
            :data="allowancelist"  
            :columns="columns" :show-row-hover="false"   
            border show-index index-text="#" 
              :expand-type="false" :selection-type="false" treeType v-loading="Loading">
                <template slot="description" slot-scope="scope">
                    <el-tag v-if="scope.row.level === 1">类型</el-tag>
                    <el-tag type="success" v-else-if="scope.row.level === 2">单位(￥)</el-tag>
                </template>
                <template slot="order" slot-scope="scope">
                    <el-tag v-if="scope.row.level === 1">一级</el-tag>
                    <el-tag type="success" v-else-if="scope.row.level === 2">二级</el-tag>
                </template>
                <template slot="option" slot-scope="scope">
                    <el-tooltip effect="dark" content="编辑" placement="top" :enterable="false">
                        <el-button type="primary" icon="el-icon-edit" @click="ShowModifyAllowanceInfo(scope.row)"></el-button>
                    </el-tooltip>
                    <el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
                        <el-button type="danger" icon="el-icon-delete" @click="DeleteAllowanceInfo(scope.row)"></el-button>
                    </el-tooltip>
                </template>
            </tree-table>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.start"
                :page-sizes="[1,2,5,10]"
                :page-size="queryInfo.count"
                layout="total, sizes, prev, pager, next, jumper"
                :total="allowancetotal">
            </el-pagination>
        </el-card>
        <el-dialog title="添加津贴类型" :visible.sync="addTypeDialogVisible" width="30%"  @close="AddTypeDialogClosed" :close-on-click-modal="false">
            <el-form :model="addTypeForm" :rules="addTypeFormRules" ref="addTypeFormRef"
            label-width="100px" v-loading="AdTypeLoading">
                <el-form-item label="类型名称：" prop="Type">
                    <el-input v-model="addTypeForm.Type"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addTypeDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="AddAllowanceType" :disabled="AdTypeLoading">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="添加津贴金额" :visible.sync="addMoneyDialogVisible" width="30%" @close="AddMoneyDialogClosed" :close-on-click-modal="false">
            <el-form :model="addMoneyForm" :rules="addMoneyFormRules" ref="addMoneyFormRef"
            label-width="100px" v-loading="AdTypeLoading">
                <el-form-item label="津贴类型：">
                    <el-select style="display:table" width="30" v-model="addMoneyForm.TypeID" placeholder="请选择">
                        <el-option
                        v-for="item in allowancelist"
                        :key="item.id"
                        :label="item.type"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="津贴金额：" prop="ReWard">
                    <el-input v-model="addMoneyForm.ReWard"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addMoneyDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="AddAllowanceMoney" :disabled="AdTypeLoading">确 认</el-button>
            </span>
        </el-dialog>

        <el-dialog title="编辑津贴信息" :visible.sync="editAllowanceInfoDialogVisible" width="30%" :close-on-click-modal="false">
            <!-- <el-divider></el-divider> -->
            <el-form :model="modifyAllowanceInfoForm" :rules="modifyAllowanceInfoFormRules" ref="modifyAllowanceInfoFormRef"
            label-width="100px" v-loading="EdTypeLoading">
                <el-form-item :label="showForimItemTitle" prop="Type">
                    <el-input v-model="modifyAllowanceInfoForm.Type"></el-input>
                </el-form-item>
            </el-form>
            <!-- <el-divider></el-divider> -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="editAllowanceInfoDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="ModifyAllowanceInfo" :disabled="EdTypeLoading">确 认</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Vue from 'vue';
import TreeTable from 'vue-table-with-tree-grid'
Vue.component('tree-table',TreeTable)
export default {
    data(){
        return{
            queryInfo:{
                type:'',
                start:1,
                count:10,
            },
            allowancelist:[],
            columns:[
                {
                    label:'津贴类型',
                    prop:'type'
                },
                {
                    label:'描述',
                    type:'template',
                    template:'description'
                },
                {
                    label:'排序',
                    type:'template',
                    template:'order'
                },
                {
                    label:'操作',
                    type:'template',
                    template:'option'
                }
            ],
            allowancetotal:0,
            addTypeDialogVisible:false,
            addTypeForm:{
                Type:''
            },
            addTypeFormRules:{
                Type: [
                    { required: true, message: '请输入名称', trigger: 'blur' }
                ]
            },
            addMoneyDialogVisible:false,
            addMoneyForm:{
                ID:0,
                TypeID:0,
                ReWard:0.00,
                IdentityID:0
            },
            addMoneyFormRules:{
                ReWard: [
                    { required: true, message: '请输入金额', trigger: 'blur' }
                ]
            },
            editAllowanceInfoDialogVisible:false,
            modifyAllowanceInfoForm:{
                ID:0,
                Type:'',
                Level:1
            },
            modifyAllowanceInfoFormRules:{
                Type: [
                    { required: true, message: '请输入修改信息', trigger: 'blur' }
                ]
            },
            showForimItemTitle:'',
            Loading:false,
            AdTypeLoading:false,
            EdTypeLoading:false,
            
        }
    },
    created(){
        this.GetAllowanceTypeDic()
    },
    methods:{
        async GetAllowanceTypeDic(){
            this.Loading = true
            const {data:res} = await this.$http.get('AllowanceType/v1/AllowanceTypeDicQuery',{params: this.queryInfo})
            this.Loading = false
            if(res.code !== 1){
                return this.$message.error('获取津贴字典失败');
            }
            this.allowancelist = res.data.allowanceTypeDics
            this.allowancetotal = res.data.total
        },
        handleSizeChange(count){
            this.queryInfo.count = count
            this.GetAllowanceTypeDic()
        },
        handleCurrentChange(start){
            this.queryInfo.start = start
            this.GetAllowanceTypeDic()
        },
        AddTypeDialogClosed(){
            this.$refs.addTypeFormRef.resetFields()
        },
        async AddAllowanceType(){
            this.$refs.addTypeFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }   
            this.AdTypeLoading = true
            let _this = this
            await this.$http.post('AllowanceType/v1/AllowanceType',this.addTypeForm).
            then(function (response) {
                if(response.data.code != 1)
                {
                    _this.AdTypeLoading = false
                    return _this.$message.error('添加津贴类型失败')
                }
            }).catch(function (error) {
                _this.AdTypeLoading = false
                return _this.$message.error(error)
            });
            this.AdTypeLoading = false
            this.addTypeDialogVisible = false
            this.GetAllowanceTypeDic()
            return this.$message.success('添加津贴类型成功')
        },
        AddMoneyDialogClosed(){
            this.$refs.addMoneyFormRef.resetFields()
        },
        ShowAddMoneyDialog()
        {
            if(this.allowancelist.length > 0)
            {
                this.addMoneyForm.TypeID = this.allowancelist[0].id
                this.addMoneyDialogVisible = true
            }
            else
            {
                return this.$message.error('需要先添加津贴类型');
            }
        },
        async AddAllowanceMoney(){
            this.$refs.addMoneyFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }   
            //console.log(this.addMoneyForm)
            this.AdTypeLoading = true
            let _this = this
            this.addMoneyForm.ReWard = parseFloat(this.addMoneyForm.ReWard)
            await this.$http.post('Allowance/v1/Allowance',this.addMoneyForm).
            then(function (response) {
                if(response.data.code != 1)
                {
                    _this.AdTypeLoading = false
                    return _this.$message.error('添加津贴类型失败')
                }
            }).catch(function (error) {
                _this.AdTypeLoading = false
                return _this.$message.error(error)
            });
             this.AdTypeLoading = false
            this.addMoneyDialogVisible = false
            this.GetAllowanceTypeDic()
            return this.$message.success('添加津贴金额成功')
        },
        ShowModifyAllowanceInfo(allowance){
            let that = this
            if(allowance.level == 1){
                that.showForimItemTitle = '类型名称：'
            }
            else{
                that.showForimItemTitle = '金额：'
            }
            that.modifyAllowanceInfoForm.ID = allowance.id
            that.modifyAllowanceInfoForm.Type = allowance.type
            that.modifyAllowanceInfoForm.Level = allowance.level
            that.editAllowanceInfoDialogVisible = true
        },
        async DeleteAllowanceInfo(allowanceinfo){
            let that = this
            const confirmResult = await that.$confirm('此操作将永远删除该津贴信息, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return that.$message.info('已取消删除')
            }
            if(allowanceinfo.level === 1)
            {
                that.Loading = true
                await that.$http.delete('AllowanceType/v1/AllowanceTypeParentByID',{params: {id : allowanceinfo.id}}).
                then(function (response) {
                if(response.data.code != 1)
                {
                    that.$message.info('删除失败')
                }
                else
                {
                    that.$message.success('删除成功')
                }
                }).catch(function (error) {
                    that.Loading = false
                    return that.$message.info(error)
                });
                that.Loading = false
            }
            else
            {
                that.Loading = true
                await that.$http.delete('AllowanceType/v1/AllowanceTypeByID',{params: {id : allowanceinfo.id}}).
                then(function (response) {
                if(response.data.code != 1)
                {
                    that.$message.info('删除失败')
                }
                else
                {
                    that.$message.success('删除成功')
                }
                }).catch(function (error) {
                    that.Loading = false
                    return that.$message.info(error)
                });
                that.Loading = false
            }
            that.GetAllowanceTypeDic()
        },
        async ModifyAllowanceInfo(){
            this.$refs.modifyAllowanceInfoFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }   
            this.EdTypeLoading = true
            let _this =this
            await this.$http.put('AllowanceType/v1/AllowanceType',this.modifyAllowanceInfoForm).
            then(function (response) {
                if(response.data.code != 1)
                {
                    _this.EdTypeLoading = false
                    return _this.$message.error('修改津贴类型失败')
                }
            }).catch(function (error) {
                _this.EdTypeLoading = false
                return _this.$message.error(error)
            });
            this.EdTypeLoading = false
            this.editAllowanceInfoDialogVisible = false
            this.GetAllowanceTypeDic()
            this.$message.success('修改津贴金额成功')
        }
    }
}
</script>

<style  lang="less" scoped>
.treetable{
    margin-top: 15px;
}
</style>